import { Component, OnInit } from '@angular/core';
import {Mapa} from './mapa';
import {CoordenadasService} from './coordenadas.service';
import {Observable} from 'rxjs';
import {AgmCoreModule} from '@agm/core';
import { NgModule } from '@angular/core';

@Component({
  
  selector: 'app-mapa',
  templateUrl: './mapa.component.html',
  styleUrls: ['./mapa.component.css']
})
export class MapaComponent implements OnInit {
  title = 'Ruta Upaxer';
  lat: number = 19.3040946;
  lng: number  = -99.192218;
  
jsonCoordenadas:Mapa[];
  constructor(private coordenadasService: CoordenadasService) { }

  ngOnInit() {

    
  }

}
