import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Mapa} from './mapa';
import {Proyecto} from './proyecto';
import {Usuario} from './usuario';

@Injectable({
  providedIn: 'root'
})
export class CoordenadasService {
//private urlEndpoit = "http://localhost:8080/api";

 // private urlEndpoit = "http://upaxdevconpubliclb-253951155.us-east-1.elb.amazonaws.com:8080/api";//DEV
  private urlEndpoit = "https://containers.upaxqa.com/api";//QA

  constructor(private http: HttpClient) {

  }

  search(queryString: string) {
    let _PAX = queryString;
    console.log(_PAX);
  }

  getCoordenadas(jsoninfo): Observable<any> {
    /*  return this.http.get<Mapa[]>(this.urlEndpoit+'/coordenadas'); */
    let json = JSON.stringify(jsoninfo);
    let params = json;
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(this.urlEndpoit + '/coordenadas', params, {headers: headers});
  }

  getProyectos(): Observable<Proyecto[]> {
    return this.http.get<Proyecto[]>(this.urlEndpoit + '/proyectos');
  }


  getUsuarios(proy): Observable<any> {
    let json = JSON.stringify(proy);
    let params = json;
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(this.urlEndpoit + '/usuarios', params, {headers: headers});
  }


}
