import {Component, OnInit} from '@angular/core';
import {Mapa} from './mapa/mapa';
import {CoordenadasService} from './mapa/coordenadas.service';
import {Observable} from 'rxjs';
import {FormControl} from '@angular/forms'
import {Proyecto} from './mapa/proyecto';
import {Usuario} from './mapa/usuario';
import {NgbCalendar, NgbDate, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {map, startWith} from 'rxjs/operators';
import {MatAutocompleteSelectedEvent} from '@angular/material';
import {isUndefined} from 'util';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2'

export enum KEY_CODE {
  UP_ARROW = 38,
  DOWN_ARROW = 40,
  TAB_KEY = 9
  }
  export interface User {
    name: string;
  }
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  styles: [`
    .custom-day {
      text-align: center;
      padding: 0.185rem 0.25rem;
      display: inline-block;
      height: 2rem;
      width: 2rem;
    }
    .custom-day.focused {
      background-color: #e6e6e6;
    }
    .custom-day.range, .custom-day:hover {
      background-color: #ea5b0a;
      color: white;
    }
    .custom-day.faded {
      background-color: rgba(239, 138, 13, 0.5);
    }
  `]
})

export class AppComponent implements OnInit{
  myControl = new FormControl();
  filteredOptions: Observable<any[]>;
  model: NgbDateStruct;
  date: {year: number, month: number};
  hoveredDate: NgbDate;
  fromDate: NgbDate;
  toDate: NgbDate;
  title = 'Ruta Upaxer';
  lat: number ;
  lng: number;
  lat2: number ;
  lng2: number ;
  latInicio: number = 19.3040946;
  lngInicio: number  = -99.192218;
  proyectovalue :string;
  usuariovalue : string;
  jsonCoordenadas:Mapa[];
  jsonProyectos:Proyecto[];
  jsonUsuarios:Usuario[];
  options:Usuario[];
  results: any[] = [];
  pax;



 queryField: FormControl = new FormControl();
    constructor(private coordenadasService: CoordenadasService, private calendar: NgbCalendar,private spinner: NgxSpinnerService) {
      this.fromDate = calendar.getToday();
    this.toDate = calendar.getNext(calendar.getToday(), 'd', 10);
    }
    onUpaxerChanged(event: MatAutocompleteSelectedEvent,option) {
      console.log(event.option.value);
      let jsonUpx=event.option.value;
      this.usuariovalue=jsonUpx.id;
      console.log(this.usuariovalue);
    }
    onDateSelection(date: NgbDate) {
      if (!this.fromDate && !this.toDate) {
        this.fromDate = date;
      } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
        this.toDate = date;
      } else {
        this.toDate = null;
        this.fromDate = date;
      }
    }

    isHovered(date: NgbDate) {
      return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
    }

    isInside(date: NgbDate) {
      return date.after(this.fromDate) && date.before(this.toDate);
    }

    isRange(date: NgbDate) {
      return date.equals(this.fromDate) || date.equals(this.toDate) || this.isInside(date) || this.isHovered(date);
    }

    getFecha(){
      this.spinner.show();
      console.log(this.usuariovalue);
      if (isUndefined(this.usuariovalue)) {
        this.spinner.hide();
        Swal({
          text:'Debe seleccionar un usuario!',
          type: 'warning',
          customClass: 'animated tada',
          confirmButtonText: 'Aceptar',
          confirmButtonColor: "#ea5b0a"
        })
      } else {
        console.log("usuario ok"+ this.usuariovalue);
        let json;
        let diaF:Number;
        let mesF:Number;
        let anioF:Number;
        let diaT:Number;
        let mesT:Number;
        let anioT:Number;
        this.proyectovalue="-1";
          diaF=-1;
          mesF=-1;
          anioF=-1;
          diaT=-1;
          mesT=-1;
          anioT=-1;
        if(JSON.stringify(this.fromDate)!=='null'){
          let jsonF = JSON.parse(JSON.stringify(this.fromDate));
          diaF=jsonF.day;
          mesF=jsonF.month;
          anioF=jsonF.year;
        }
        if( JSON.stringify(this.toDate)!=='null'){
          let jsonT = JSON.parse(JSON.stringify(this.toDate));
          diaT=jsonT.day;
          mesT=jsonT.month;
          anioT=jsonT.year;
        }
        json=
          {
            idProyecto: this.proyectovalue,
            idUsuario: this.usuariovalue,
            diaInicio:diaF,
            mesInicio:mesF,
            anioInicio:anioF,
            diaFin:diaT,
            mesFin:mesT,
            anioFin:anioT
          };

        this.coordenadasService.getCoordenadas(json).subscribe(
          paths => {
            console.log(JSON.stringify(paths));
            if (paths.length > 0) {

              this.jsonCoordenadas = paths;
              let tamanio = this.jsonCoordenadas.length;
              this.lat=this.jsonCoordenadas[0].lat
              this.lng = this.jsonCoordenadas[0].lng
              this.latInicio=this.jsonCoordenadas[0].lat
              this.lngInicio = this.jsonCoordenadas[0].lng
              this.lat2=this.jsonCoordenadas[tamanio-1].lat
              this.lng2=this.jsonCoordenadas[tamanio-1].lng
              this.spinner.hide();

            } else {
              this.spinner.hide();
              Swal({
                text:'No hay datos con ese rango de fechas y ese usuario!',
                type: 'warning',
                customClass: 'animated tada',
                confirmButtonText: 'Aceptar',
                confirmButtonColor: "#ea5b0a"
              })

            }

          }

        )
      }

    }
    onProyectChange(value){
      this.proyectovalue=value;

    }
    onUserChange(value){
      this.usuariovalue=value;
    }


    displayFn(user?: Usuario): string | undefined {
      return user ? user.nombre : undefined;
    }

    private _filter(nombre: string): Usuario[] {
      const filterValue = nombre.toLowerCase();

      return this.jsonUsuarios.filter(option => option.nombre.toLowerCase().indexOf(filterValue) === 0);
    }
    ngOnInit() {

      this.queryField.valueChanges
        .subscribe( result => {
        console.log(result)
        this.pax=result;
        let proy;
        proy=
          {
            id: this.pax,
            nombre: ""
          };
        this.coordenadasService.getUsuarios(proy).subscribe(
          users => {
              this.jsonUsuarios=users;
            this.options = users;
            if (JSON.stringify(this.options) !== 'null') {
              this.filteredOptions = this.myControl.valueChanges
              .pipe(
              startWith<string | Usuario>(''),
              map(value => typeof value === 'string' ? value : value.nombre),
              map(nombre => nombre ? this._filter(nombre) :  this.options.slice())
            );
            }

          }
        )
      });

     /*  this.coordenadasService.getProyectos().subscribe(
        proys=>{
          this.jsonProyectos=proys;
          console.log(this.jsonProyectos);
        }
      ) */

    }




}

