import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {AgmCoreModule} from '@agm/core';
import {MapaComponent} from './mapa/mapa.component';
import {CoordenadasService} from './mapa/coordenadas.service';
import {HttpClientModule} from '@angular/common/http';
import {routing} from './app.routes';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {
  MatAutocompleteModule,
  MatButtonModule,
  MatCheckboxModule,
  MatFormFieldModule,
  MatInputModule,
  MatNativeDateModule
} from '@angular/material';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import { NgxSpinnerModule } from 'ngx-spinner';

@NgModule({
  declarations: [
    AppComponent,
    MapaComponent
  ],
  imports: [
    AgmCoreModule.forRoot({
      apiKey:'AIzaSyBzJV0kucAEqWy0Y502lJldsaBIqoqa-Gk'
    }),
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    MatNativeDateModule,
    MatButtonModule,
    MatCheckboxModule,
    NgbModule,
    NgxSpinnerModule,
    ReactiveFormsModule,
    routing

  ],
  providers: [CoordenadasService],
  bootstrap: [AppComponent]
})
export class AppModule { }
platformBrowserDynamic().bootstrapModule(AppModule);
