import {RouterModule, Routes} from '@angular/router';
import {MapaComponent} from './mapa/mapa.component';

const routes: Routes = [
    {path: '', redirectTo: '/coordenadas', pathMatch: 'full'},
    {path: 'coordenadas', component: MapaComponent},
    {path: '', redirectTo: '/proyectos', pathMatch: 'full'},
    {path: 'proyectos', component: MapaComponent},
    {path: '', redirectTo: '/usuarios', pathMatch: 'full'},
    {path: 'usuarios', component: MapaComponent}
   ];

   export const routing = RouterModule.forRoot(routes);
